import React from "react";

type Props = {
  error: string;
};

function SearchPageError({ error }: Props) {
  return (
    <tbody>
      <tr className="danger">
        <td colSpan={4}>
          <strong>{error}</strong>
        </td>
      </tr>
    </tbody>
  );
}

export default SearchPageError;
