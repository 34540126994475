export const loginPath = "/v1/mit/login";
export const searchKitIdsPath = "/v1/mit/search/kitids";
export const searchSampleIdsPath = "/v1/mit/search/sampleids";
export const searchPartnersamplesPartnersampleIdsPath =
  "/v1/mit/partnersamples/search/partnersampleids";
export const searchPartnersamplesSampleIdsPath =
  "/v1/mit/partnersamples/search/sampleids";
export const userSampleKey = "usersample";
export const partnerSampleKey = "partnersample";
export const searchTypes: { [key: string]: any } = {
  [userSampleKey]: {
    displayName: "Clinical samples",
  },
  [partnerSampleKey]: {
    displayName: "FFS samples", // Fee for Service
  },
};
// list of DNA Plate partners
// ref: https://github.com/myhelix/hmapi/blob/master/models/catalog/json/sources.json
export const partnerSources = [
  {
    sourceId: "SI-3ZK8-TASEA",
    name: "GlobalGeneCorp - FFSE",
  },
  {
    sourceId: "SI-EXFD-PYP4A",
    name: "Temple - FFSE",
  },
];
