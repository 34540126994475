import { environment } from "config/env";

function getConfig() {
  let config = {
    googleClientId:
      "1092122260592-cqc1apos8v38rtmge8nv7qjl14tol0es.apps.googleusercontent.com",
    limsApiUrl: "https://lims-api.staging.helix.com",
  };

  if (environment === "production") {
    config = {
      ...config,
      googleClientId:
        "1092122260592-0pbm5shqvqqrtdlblnhjrohqla5427n6.apps.googleusercontent.com",
      limsApiUrl: "https://lims-api.helix.com",
    };
  } else if (environment === "development") {
    config = {
      ...config,
      // limsApiUrl: "http://localhost:3140",
    };
  }

  return config;
}

export const config = getConfig();
