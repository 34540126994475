import React, { ChangeEvent } from "react";
/* @ts-ignore */
import { Modal, Button } from "react-bootstrap";

type Props = {
  isVisible: boolean;
  isValid: boolean;
  loading: boolean;
  onModalClose: () => void;
  onKitIdChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSampleIdChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onReasonChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
};

const SearchModal = ({
  isVisible,
  isValid,
  loading,
  onModalClose,
  onKitIdChange,
  onSampleIdChange,
  onReasonChange,
  onSubmit,
}: Props) => (
  <Modal show={isVisible} onHide={onModalClose}>
    <Modal.Header closeButton>
      <Modal.Title>ID Finder</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="form-group">
        <label htmlFor="kit-ids">KIT ID</label>
        <p className="help-block">If multiple, enter each ID on a new line.</p>
        <textarea
          data-testid="kit-ids"
          className="form-control"
          rows={5}
          id="kit-ids"
          onChange={onKitIdChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="sample-ids">Sample ID</label>
        <p className="help-block">If multiple, enter each ID on a new line.</p>
        <textarea
          data-testid="sample-ids"
          className="form-control"
          rows={5}
          id="sample-ids"
          onChange={onSampleIdChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="reason">Reason</label>
        <textarea
          data-testid="reason"
          className="form-control"
          rows={5}
          id="reason"
          onChange={onReasonChange}
        />
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button
        bsStyle="primary"
        bsSize="large"
        disabled={!isValid || loading}
        onClick={onSubmit}
      >
        {loading ? "Loading..." : "Submit"}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default SearchModal;
