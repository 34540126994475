import React from "react";
import { Mapping } from "components/SearchPage";

type Props = {
  mappings: Mapping[];
};

function SearchPageResult({ mappings }: Props) {
  const date = new Date().toLocaleDateString();

  return (
    <tbody>
      {mappings.map((mapping, idx) => {
        return (
          <tr key={idx}>
            <td />
            <td data-testid="date">{date}</td>
            <td data-testid="kitId">{mapping.kitId}</td>
            <td data-testid="sampleId">{mapping.sampleId}</td>
          </tr>
        );
      })}
    </tbody>
  );
}

export default SearchPageResult;
