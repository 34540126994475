import React, { useState } from "react";
import Login from "components/Login";
import SearchPage from "components/SearchPage";
import Navigation from "components/Navigation";
import { Provider as RollbarProvider } from "@rollbar/react";
import { rollbarConfig } from "config/rollbar";

export type User = {
  name: string;
  accessToken: string;
};

function App() {
  const [user, setUser] = useState<User | null>(null);

  function onLogin(user: User) {
    setUser(user);
  }

  function onLogout() {
    setUser(null);
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <div className="container">
        <div className="app-header">
          <Navigation user={user} onLogout={onLogout} />
          {user ? <SearchPage user={user} /> : <Login onLogin={onLogin} />}
        </div>
      </div>
    </RollbarProvider>
  );
}

export default App;
