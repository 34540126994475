function getEnvironment() {
  if (window.location.href.match(/localhost/)) {
    return "development";
  } else if (window.location.href.match(/staging/)) {
    return "staging";
  }
  return "production";
}

export const environment = getEnvironment();
