import React, { useState } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { User } from "App";
import { loginPath } from "config/constants";
import { config } from "config";

type Props = {
  onLogin: (user: User) => void;
};

function Login({ onLogin }: Props) {
  const [error, setError] = useState("");

  async function onSuccess(
    r: GoogleLoginResponse | GoogleLoginResponseOffline
  ) {
    if (r.code) {
      setError("Network is offline");
      return;
    }

    r = r as GoogleLoginResponse;
    await verifyAuth(r.tokenId, r.getBasicProfile().getName());
  }

  function onFailure() {
    setError("Unexpected google auth error");
  }

  function verifyAuth(idToken: string, name: string) {
    return fetch(config.limsApiUrl + loginPath, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `idtoken=${idToken}`,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error.message);
        }

        onLogin({ accessToken: data.access_token, name });
      })
      .catch(() => setError("User unauthorized"));
  }

  return (
    <div className="login">
      <div className="google-container button" data-test="login-wrapper">
        <GoogleLogin
          clientId={config.googleClientId}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          className="google-button"
        />
      </div>
      {error && (
        <div
          className="alert alert-danger unauthorized-user-dialog"
          role="alert"
        >
          <strong>{error}</strong>
        </div>
      )}
    </div>
  );
}

export default Login;
