import React from "react";
import { User } from "App";

type Props = {
  user: User | null;
  onLogout: () => void;
};

function Navigation({ user, onLogout }: Props) {
  return (
    <nav className="navbar navigation">
      <div className="container-fluid">
        <div className="navbar-header">
          <div className="logo navbar-brand">
            <img
              alt="Helix"
              src={process.env.PUBLIC_URL + "/images/helix_logo.svg"}
            />
          </div>
        </div>
        {user && (
          <div>
            <button
              onClick={onLogout}
              type="button"
              className="btn btn-link btn-logout navbar-btn navbar-right"
            >
              Logout
            </button>
            <p className="navbar-text navbar-right navbar-username">
              {user.name}
            </p>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navigation;
