import React, { ChangeEvent, useState } from "react";
import SearchModal from "components/SearchModal";
import SearchPageResult from "components/SearchPageResult";
import PartnerSamplesSearchModal from "components/partnersamples/PartnerSamplesSearchModal";
import PartnerSamplesSearchPageResult from "components/partnersamples/PartnerSamplesSearchPageResult";
import { User } from "App";
import {
  partnerSampleKey,
  searchKitIdsPath,
  searchPartnersamplesPartnersampleIdsPath,
  searchPartnersamplesSampleIdsPath,
  searchSampleIdsPath,
  searchTypes,
  userSampleKey,
} from "config/constants";
import { config } from "config";
import axios from "axios";
import { Dropdown, MenuItem, Button } from "react-bootstrap";
import SearchPageError from "./SearchPageError";

type Props = {
  user: User;
};

export type Mapping = {
  kitId: string;
  sampleId: string;
};

export interface PartnerSampleFields {
  partnerSampleIds: string[] | null;
  sampleIds: string[] | null;
  sourceId: string | null;
  reason: string | null;
}

export type PartnerSampleMapping = {
  partnerSampleId: string;
  sampleId: string;
};

type State = {
  showModal: boolean;
  activeModal: string;
  isValid: boolean;
  loading: boolean;
  error: string;
  reason: string | null;
  kitIds: string[] | null;
  sampleIds: string[] | null;
  mappings: Mapping[] | null;
  partnerSampleMappings: PartnerSampleMapping[] | null;
  partnerSampleFields: PartnerSampleFields;
};

function SearchPage({ user }: Props) {
  const defaultModal = userSampleKey;

  const [state, setState] = useState<State>({
    showModal: false,
    isValid: false,
    activeModal: defaultModal,
    reason: null,
    kitIds: null,
    sampleIds: null,
    loading: false,
    error: "",
    mappings: null,
    partnerSampleFields: {} as PartnerSampleFields,
    partnerSampleMappings: null,
  });
  const {
    error,
    mappings,
    showModal,
    isValid,
    loading,
    reason,
    kitIds,
    sampleIds,
    partnerSampleMappings,
    activeModal,
  } = state;

  const stringToArray = (str: string): string[] | null =>
    !!str.trim() ? str.trim().split(/\r\n|\r|\n/g) : null;

  const validateInput = (
    reason: string | null,
    kitIds: string[] | null,
    sampleIds: string[] | null
  ) => {
    setState((prevState) => ({
      ...prevState,
      isValid: !!reason && (!!kitIds || !!sampleIds),
    }));
  };

  function onKitIdChange({ target }: ChangeEvent<HTMLTextAreaElement>) {
    const { value } = target;
    const kitIds = stringToArray(value);
    setState((prevState) => ({
      ...prevState,
      kitIds,
    }));
    validateInput(reason, kitIds, sampleIds);
  }

  function onSampleIdChange({ target }: ChangeEvent<HTMLTextAreaElement>) {
    const sampleIds = stringToArray(target.value);
    setState((prevState) => ({
      ...prevState,
      sampleIds,
    }));
    validateInput(reason, kitIds, sampleIds);
  }

  function onReasonChange({ target }: ChangeEvent<HTMLTextAreaElement>) {
    const reason = target.value.trim();
    setState((prevState) => ({
      ...prevState,
      reason,
    }));
    validateInput(reason, kitIds, sampleIds);
  }

  function openModal(activeModal: string) {
    setState((prevState) => ({
      ...prevState,
      activeModal: activeModal,
      showModal: true,
      error: "",
      reason: null,
      kitIds: null,
      sampleIds: null,
      partnerSampleFields: {} as PartnerSampleFields,
      partnerSampleMappings: null,
    }));
  }

  function onPartnerSampleFieldChange(
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>,
    field: keyof PartnerSampleFields
  ) {
    const { partnerSampleFields } = state;
    let { value } = target;
    switch (field) {
      case "partnerSampleIds":
        const partnerSampleIds = stringToArray(value);
        partnerSampleFields[field] = partnerSampleIds;
        break;
      case "sampleIds":
        const sampleIds = stringToArray(value);
        partnerSampleFields[field] = sampleIds;
        break;
      case "reason":
        const reason = value.trim();
        partnerSampleFields[field] = reason;
        break;
      case "sourceId":
        partnerSampleFields[field] = value;
        break;
      default:
        throw new Error(`unknown field: ${field}"`);
    }

    setState((prevState) => ({
      ...prevState,
      partnerSampleFields: partnerSampleFields,
      isValid:
        !!partnerSampleFields.reason &&
        ((!!partnerSampleFields.partnerSampleIds &&
          !!partnerSampleFields.sourceId) ||
          !!partnerSampleFields.sampleIds),
    }));
  }

  function onModalClose() {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
    }));
  }

  async function onSubmit() {
    const helixPrefixEXM = "EXM-";
    const helixPrefixRTM = "RTM-";
    let mappings: Mapping[] = [];
    let error = "";

    setState((prevState) => ({
      ...prevState,
      loading: true,
      error,
      mappings: null,
      partnerSampleMappings: null,
    }));

    if (kitIds && reason) {
      const shouldKeepDash = (kitId: string) =>
        kitId.startsWith(helixPrefixEXM) || kitId.startsWith(helixPrefixRTM);

      const ids = kitIds.map((kitId) =>
        shouldKeepDash(kitId) ? kitId : kitId.replace(/-/g, "")
      );

      const kitIdMapping = (await retrieveData(
        searchKitIdsPath,
        user.accessToken,
        JSON.stringify({
          reason,
          ids: Array.from(new Set(ids)),
        })
      )) as Mapping[];

      if (kitIdMapping === null) {
        error = "Server error encountered";
      } else if (kitIdMapping.length === 0) {
        error = "No records found";
      } else {
        mappings = sort(ids, kitIdMapping, "kitId");
      }
    }

    if (sampleIds && reason) {
      const sampleIdMapping = (await retrieveData(
        searchSampleIdsPath,
        user.accessToken,
        JSON.stringify({
          reason,
          ids: Array.from(new Set(sampleIds)),
        })
      )) as Mapping[];

      if (sampleIdMapping === null) {
        error = "Server error encountered";
      } else if (sampleIdMapping.length === 0) {
        error = "No records found";
      } else {
        mappings = sort(sampleIds, sampleIdMapping, "sampleId");
      }
    }

    setState((prevState) => ({
      ...prevState,
      loading: false,
      isValid: false,
      mappings,
      error,
    }));

    onModalClose();
  }

  async function retrieveData(path: string, accessToken: string, payload: any) {
    try {
      const response = await axios.post(config.limsApiUrl + path, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `bearer ${accessToken}`,
        },
      });

      if (response?.status === 200) {
        return response.data.mitResults;
      }

      throw new Error("Server error encountered");
    } catch (err) {
      console.log(err);
    }
    return null;
  }

  async function onPartnerSampleSubmit() {
    let partnerSampleMappings: PartnerSampleMapping[] = [];
    let error = "";
    const { partnerSampleFields } = state;

    setState((prevState) => ({
      ...prevState,
      loading: true,
      error: "",
      userSampleMappings: null,
      partnerSampleMappings: null,
    }));

    if (
      partnerSampleFields.partnerSampleIds &&
      partnerSampleFields.sourceId &&
      partnerSampleFields.reason
    ) {
      const partnerSampleIdMapping = (await retrieveData(
        searchPartnersamplesPartnersampleIdsPath,
        user.accessToken,
        {
          partnerSampleIds: Array.from(
            new Set(partnerSampleFields.partnerSampleIds)
          ),
          sourceId: partnerSampleFields.sourceId,
          reason: partnerSampleFields.reason,
        } as PartnerSampleFields
      )) as PartnerSampleMapping[];

      if (partnerSampleIdMapping === null) {
        error = "Server error encountered";
      } else if (partnerSampleIdMapping.length === 0) {
        error = "No records found";
      } else {
        partnerSampleMappings = sort(
          partnerSampleFields.partnerSampleIds,
          partnerSampleIdMapping,
          "partnerSampleId"
        );
      }
    }

    if (partnerSampleFields.sampleIds && partnerSampleFields.reason) {
      const sampleIdMapping = (await retrieveData(
        searchPartnersamplesSampleIdsPath,
        user.accessToken,
        {
          sampleIds: Array.from(new Set(partnerSampleFields.sampleIds)),
          reason: partnerSampleFields.reason,
        } as PartnerSampleFields
      )) as PartnerSampleMapping[];

      if (sampleIdMapping === null) {
        error = "Server error encountered";
      } else if (sampleIdMapping.length === 0) {
        error = "No records found";
      } else {
        partnerSampleMappings = sort(
          partnerSampleFields.sampleIds,
          sampleIdMapping,
          "sampleId"
        );
      }
    }

    setState((prevState) => ({
      ...prevState,
      loading: false,
      isValid: false,
      partnerSampleMappings,
      error,
    }));

    onModalClose();
  }

  return (
    <div>
      <div className="panel panel-default">
        <div className="panel-heading">
          <Dropdown id="find-dropdownbutton">
            <Button
              bsStyle="primary"
              aria-label="find-button"
              onClick={() => openModal(defaultModal)}
            >
              <span className="glyphicon glyphicon-search" aria-hidden="true" />{" "}
              Find ID
            </Button>
            <Dropdown.Toggle
              bsStyle="primary"
              data-testid="find-dropdownbutton"
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(searchTypes).map((key) => (
                <MenuItem key={key} onClick={() => openModal(key)}>
                  {searchTypes[key].displayName}
                </MenuItem>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {(() => {
          switch (activeModal) {
            case userSampleKey:
              return (
                <table data-testid="header-table" className="table">
                  <thead>
                    <tr>
                      <th />
                      <th className="header">Date requested</th>
                      <th className="header">Kit id</th>
                      <th className="header">Sample id</th>
                    </tr>
                  </thead>
                  {error !== "" && <SearchPageError error={error} />}
                  {mappings && <SearchPageResult mappings={mappings} />}
                </table>
              );
            case partnerSampleKey:
              return (
                <table data-testid="header-table" className="table">
                  <thead>
                    <tr>
                      <th />
                      <th className="header">Date requested</th>
                      <th className="header">Partner Sample id</th>
                      <th className="header">Sample id</th>
                    </tr>
                  </thead>
                  {error !== "" && <SearchPageError error={error} />}
                  {partnerSampleMappings && (
                    <PartnerSamplesSearchPageResult
                      mappings={partnerSampleMappings}
                    />
                  )}
                </table>
              );
            default:
              return (
                <table data-testid="header-table" className="table"></table>
              );
          }
        })()}
      </div>

      <SearchModal
        isVisible={showModal && activeModal === userSampleKey}
        isValid={isValid}
        loading={loading}
        onModalClose={onModalClose}
        onKitIdChange={onKitIdChange}
        onSampleIdChange={onSampleIdChange}
        onReasonChange={onReasonChange}
        onSubmit={onSubmit}
      />

      <PartnerSamplesSearchModal
        isVisible={showModal && activeModal === partnerSampleKey}
        isValid={isValid}
        loading={loading}
        onModalClose={onModalClose}
        onFieldChange={onPartnerSampleFieldChange}
        onSubmit={onPartnerSampleSubmit}
      />
    </div>
  );
}

export default SearchPage;

// Sort mapping to match the requested ids order
export function sort<T extends Mapping | PartnerSampleMapping>(
  ids: string[],
  mapping: T[],
  field: keyof T
) {
  const arr: T[] = [];

  for (let i = 0; i < ids.length; i++) {
    const m = mapping.find((m) => m[field] === ids[i]);
    if (m) {
      arr.push(m);
    }
  }

  return arr;
}
