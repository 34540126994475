import { environment } from "config/env";

export const rollbarConfig = {
  accessToken: "9b13ad85ea074d3a813e6e93842bad53",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      environment,
      javascript: {
        code_version: process.env.REACT_APP_GIT_SHA,
        source_map_enabled: true,
      },
    },
  },
  hostSafeList: ["helix.com"],
};
