import {
  partnerSampleKey,
  partnerSources,
  searchTypes,
} from "config/constants";
import React, { ChangeEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import { PartnerSampleFields } from "../SearchPage";

type Props = {
  isVisible: boolean;
  isValid: boolean;
  loading: boolean;
  onModalClose: () => void;
  onFieldChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>,
    field: keyof PartnerSampleFields
  ) => void;
  onSubmit: () => void;
};

const PartnerSamplesSearchModal = ({
  isVisible,
  isValid,
  loading,
  onModalClose,
  onFieldChange,
  onSubmit,
}: Props) => (
  <Modal show={isVisible} onHide={onModalClose}>
    <Modal.Header closeButton>
      <Modal.Title>
        {searchTypes[partnerSampleKey].displayName} ID Finder
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="form-group">
        <label htmlFor="partner-sample-ids">Partner Sample ID</label>
        <p className="help-block">If multiple, enter each ID on a new line.</p>
        <textarea
          data-testid="partner-sample-ids"
          className="form-control"
          rows={5}
          id="partner-sample-ids"
          onChange={(e) => onFieldChange(e, "partnerSampleIds")}
        />
      </div>

      <div className="form-group">
        <label htmlFor="sample-ids">Sample ID</label>
        <p className="help-block">If multiple, enter each ID on a new line.</p>
        <textarea
          data-testid="sample-ids"
          className="form-control"
          rows={5}
          id="sample-ids"
          onChange={(e) => onFieldChange(e, "sampleIds")}
        />
      </div>

      <div className="form-group">
        <label htmlFor="source-id">Partner</label>
        <p className="help-block">
          Select a partner name if you are looking for Sample ID using Partner
          Sample ID.
        </p>
        <select
          className="form-control"
          id="source-id"
          data-testid="source-id"
          defaultValue={""}
          onChange={(e) => onFieldChange(e, "sourceId")}
        >
          <option data-testid="source-id-option" value="">
            Select one
          </option>
          {partnerSources.map((item) => (
            <option
              data-testid="source-id-option"
              value={item.sourceId}
              key={item.sourceId}
            >
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="reason">Reason</label>
        <textarea
          data-testid="reason"
          className="form-control"
          rows={5}
          id="reason"
          onChange={(e) => onFieldChange(e, "reason")}
        />
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button
        bsStyle="primary"
        bsSize="large"
        disabled={!isValid || loading}
        onClick={onSubmit}
      >
        {loading ? "Loading..." : "Submit"}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default PartnerSamplesSearchModal;
