import React from "react";
import { PartnerSampleMapping } from "components/SearchPage";

type Props = {
  mappings: PartnerSampleMapping[] | null;
};

function PartnerSamplesSearchPageResult({ mappings }: Props) {
  const date = new Date().toLocaleDateString();

  return (
    <tbody>
      {mappings &&
        mappings.map((mapping, idx) => (
          <tr key={idx}>
            <td />
            <td data-testid="date">{date}</td>
            <td data-testid="partnerSampleId">{mapping.partnerSampleId}</td>
            <td data-testid="sampleId">{mapping.sampleId}</td>
          </tr>
        ))}
    </tbody>
  );
}

export default PartnerSamplesSearchPageResult;
